@use '../../../sass/abstracts/functions' as f;
@use '../../../sass/layout/breakpoints' as b;
@use '../../../sass/abstracts/colors' as colors;


.detailed_analytics_container {
    display: none;

    @include b.breakpoint(large){
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: left;
        width: 97.2%;
        background-color: colors.$white01;
        border: 1px solid colors.$borderwhite;
        border-radius: f.rem(22);
        margin-block: f.rem(16);
        padding: f.rem(16);
    }
}