.contact-sidebar {
  position: fixed;
  top: 0;
  right: 0;
  width: 400px;
  height: 100vh;
  /* Ensure full viewport height */
  background-color: white;
  transition: transform 0.3s ease-in-out;
  transform: translateX(0);
  display: flex;
  flex-direction: column;
}

.contact-sidebar.hidden {
  transform: translateX(100%);
}

.contact-content {
  flex-grow: 1;
  overflow-y: auto;
  padding-bottom: 60px;
}

.comment-box {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 400px;
  background: white;
  border-top: 1px solid #ddd;
  padding: 10px;
  display: flex;
  align-items: center;
}