@use '../../../sass/abstracts/functions' as f;
@use '../../../sass/layout/breakpoints' as b;
@use '../../../sass/abstracts/colors' as colors;

.recipients-container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  
  @include b.breakpoint(small) {
    padding: 0;
  }
  
  @include b.breakpoint(large) {
    padding: 0;
  }
}

.search_container {
  display: flex;
  width: 100%;
  align-items: flex-start;

  @include b.breakpoint(medium) {
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
  }

  .search_upload_row {
      // background-color: blueviolet;
      margin-inline-end: 0;


      @include b.breakpoint-down(small) {
          width: 100%;
      }

      .logged_input {
          font-size: f.rem(14);
          // color: brown;
          border: 1px solid colors.$black04;
          border-radius: f.rem(12);
          padding-block: f.rem(7);
          padding-inline: f.rem(16);
          // background-color: chartreuse;

          ::placeholder {
              color: colors.$black04;
              opacity: 1;
          }

          ::-ms-input-placeholder {
              /* Edge 12-18 */
              color: colors.$black04;
          }

          .search_input {
              margin-inline-start: f.rem(6);
              appearance: none;
              border: none;
              background: none;
              padding: 0;
              outline: none;
              font-size: f.rem(14);
              color: colors.$black01;
              opacity: 1;
          }
      }
  }
}